import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { AuthRepository } from '../repository/auth.repositoty';
import { LoadDataSourceResponse } from '@app/shared/models';
import { LoginResponse } from '../models/responses/login-response.response';
import { TFGH_END_POINTS } from '@app/shared/common/end-points';

@Injectable()
export class AuthService implements AuthRepository {
  constructor(private httpClient: HttpClient) {}

  login(token: string): Observable<LoginResponse> {
    return this.httpClient
      .post<LoadDataSourceResponse<LoginResponse>>(`${TFGH_END_POINTS.API_BASE}/authenticate`, {"token": token})
      .pipe(map(res => res?.data || []));
  }
}
